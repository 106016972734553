import React from 'react';
import './possibility.css';
import isologo from '../../assets/isologo.png';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={isologo} alt="iso27001_logo" />
    </div>
    <div className="gpt3__possibility-content">
      <h1 className="gradient__text">Security and confidence as key values</h1>
      <p>EGMA holds the Information Security Management standard ISO 27001. The standard is among the top 4 ISO management standards recognized worldwide. It uses a risk-based approach to independently assess whether information security is managed in line with international best practices and business objectives. With this standard, EGMA demonstrates the robustness and efficiency of a data management system strongly focused on data availability, confidentiality and integrity.
      <br/>Not only does it fully align to ENGIE cybersecurity priorities, it also provides clients and prospects with an additional level of confidence in using EGMA.</p>
    </div>
  </div>
);

export default Possibility;
