import React from 'react';
import './blog.css';
import mapGas from '../../assets/map-gas.png';
import mapPower from '../../assets/map-power.png';
import mapCertificates from '../../assets/map-certificates.png';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">We are offering a diverse and growing list of products with a unique access to global markets.</h1>
    </div>
    <div className='gpt3__blogs'>
      <div className="gpt3__blog-container">

      <table className="nj-table">
        <thead>
          <tr>
            <th className="left" scope="col">TYPE</th>
            <th className="right" scope="col">Active</th>
          </tr>
        </thead>
        <tbody>
          <tr>

            <td>Physical Forward</td>
            <td className="right"><i className="material-icons check">done</i></td>

          </tr>
          <tr>

            <td colSpan="2">
              <div className="features">
                <div className="feature">
                  <div>Standard</div>
                  <div><i className="material-icons check">done</i>
                  </div>
                </div>
                <div className="feature">
                  <div>Time Spreads</div>
                  <div><i className="material-icons check">done</i>
                  </div>
                </div>
                <div className="feature">
                  <div>Location Spreads</div>
                  <div><i className="material-icons check">done</i>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>

            <td>Financial Swap</td>
            <td className="right"><i className="material-icons check">done</i></td>

          </tr>
          <tr>

            <td colSpan="2">
              <div className="features">
                <div className="feature">
                  <div>Standard</div>
                  <div><i className="material-icons check">done</i>
                  </div>
                </div>
                <div className="feature">
                  <div>Time Spreads</div>
                  <div><i className="material-icons check">done</i>
                  </div>
                </div>
                <div className="feature">
                  <div>Location Spreads</div>
                  <div><i className="material-icons check">done</i>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Certificates</td>
            <td className="right"><i className="material-icons check">done</i></td>
          </tr>
          <tr>
            <td>Basket</td>
            <td className="right"><i className="material-icons check">done</i></td>
          </tr>
        </tbody>
        </table>

      </div>
      <div className="gpt3__blog-container">
        <h2 className='gradient__text'>Our clients are able to connect energy markets with EGMA in an easy and simplified way.</h2>
        <div className="warpper">
          <input className="rradio" id="r_one" name="group" type="radio" defaultChecked />
          <input className="rradio" id="r_two" name="group" type="radio" />
          <input className="rradio" id="r_three" name="group" type="radio" />
          <div className="rtabs">
            <label className="rtab" id="one-tab" htmlFor="r_one">Gas</label>
            <label className="rtab" id="two-tab" htmlFor="r_two">Power</label>
            <label className="rtab" id="three-tab" htmlFor="r_three">Certificates</label>
          </div>
          <div className="rpanels">
          <div className="rpanel" id="one-panel">
            <img src={mapGas} alt="map" />
          </div>
          <div className="rpanel" id="two-panel">
            <img src={mapPower} alt="map" />
          </div>
          <div className="rpanel" id="three-panel">
            <img src={mapCertificates} alt="map" />
          </div>
          </div>
        </div>

      </div>
    </div>
  </div>
);

export default Blog;
