import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatEGMA.css';

const WhatEGMA = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wEGMA">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="What is EGMA" text="From indicative live prices to realized bilateral transactions within seconds, we aim to transform the market access experience for our clients. The whole value chain is therefore reimagined. Hands-on, our customers can now directly create requests for quotes on both physical and financial energy products and automation is a key element." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">EGMA is ENGIE’s in-house trading platform. It is offered to all ENGIE’ clients free of charge.</h1>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Accelerated execution time" text="From indicative live prices to realized bilateral transactions within seconds, you can now execute products across maturities seamlessly." />
      <Feature title="Tailor made products" text="Customize your dashboard to showcase your preferred products and create specific Request For Quotes on Gas and Power underlyings." />
      <Feature title="Up-to-date market insights" text="Daily, weekly and monthly comments from our economists on forex, oil, gas, power and emissions market. Access valuable data on customizable reports with specific alerts for each market." />
    </div>
  </div>
);

export default WhatEGMA;
