import React from 'react';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Take advantage of an intuitive market access platform</h1>
      <p>Our world and energy systems are changing. Decentralization and automation are becoming key drivers of the energy management of tomorrow</p>

      <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Get&nbsp;Started</button>
      </div>

      {/* <div className="gpt3__header-content__people">
        <img src={people} />
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div> */}
    </div>

    <div className="gpt3__header-image">
      <video controls className="screen-capture shadow" autoPlay={true} loop muted>
        <source src="https://gem.engie.com/img/egma-execute.webm" type="video/webm"/>
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  </div>
);

export default Header;
