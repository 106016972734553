import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Fully automated',
    text: 'From credit checks, to product pricing or deal confirmations, the process is as lean and efficient as possible.',
  },
  {
    title: 'You are our priority',
    text: 'Your dedicated originators are available to advise and support you through your experience on EGMA.',
  },
  {
    title: 'Feedback driven',
    text: 'We believe you know your own needs best, which is why EGMA is built and optimized on customer feedback.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Get a unique access to global markets</h1>
      <p>Request Access to Get Started</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
